<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-lx-cascades"></i> 週報表
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-form :inline="true"
                     :model="formInline"
                     class="demo-form-inline">
                <el-form-item label="日期:">
                    <el-date-picker v-model="formInline.date"
                                    type="date"
                                    placeholder="請選擇日期" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="exportSummary">匯出週報表</el-button>
                    <el-button type="warning"
                               @click="exportSummaryHistory">匯出舊報表</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { reportNickyExport, reportNickyExportHistory } from '../../api/index'
import { dateFormat } from '@/utils/dateFormat.js'
export default {
    data() {
        return {
            formInline: {},
        }
    },
    methods: {
        exportSummary() {
            if (!this.formInline.date) {
                this.$message.error('請選擇日期')
            } else {
                reportNickyExport({ date: dateFormat(this.formInline.date) }).then((res) => {
                    this.excelTake(res, '匯出週總表')
                })
            }
        },
        exportSummaryHistory() {
            if (!this.formInline.date) {
                this.$message.error('請選擇日期')
            } else {
                reportNickyExportHistory({ date: dateFormat(this.formInline.date) }).then((res) => {
                    this.excelTake(res, '匯出舊總表' + dateFormat(this.formInline.date))
                })
            }
        },
        //接收excel
        excelTake(res, title) {
            const xlsx = 'application/vnd.ms-excel'
            const blob = new Blob([res], { type: xlsx })
            const fileReader = new FileReader()
            let _this = this
            fileReader.readAsText(blob, 'utf-8')
            fileReader.onload = function () {
                if (res.type === 'application/json') {
                    let msg = JSON.parse(fileReader.result).message
                    if (JSON.parse(fileReader.result).code == 500) {
                        _this.$message.error(msg)
                    }
                } else {
                    const a = document.createElement('a') // 轉換完成，建立一個a標籤用於下載
                    a.download = `${title}.xlsx`
                    a.href = window.URL.createObjectURL(blob)
                    a.click()
                    a.remove()
                }
            }
        },
    },
}
</script>

<style>
</style>